import React from "react"
import TitleUnderline from "./titleUnderline"


const Benefit = ({ data }) => {
  return (
    <div className="container-benefit-partners">
      <div className="container-benefit-logo-banner">
        <svg width="593" height="52" viewBox="0 0 593 52" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 26.0869H190.003" stroke="#9BBBF5" stroke-width="2" />
          <circle cx="318.503" cy="12.0869" r="11.5" fill="#F2ACA9" />
          <circle cx="357.003" cy="38.5869" r="6" fill="#66E28E" />
          <circle cx="300.003" cy="37.5869" r="7" fill="#88AEF3" />
          <path d="M277.003 28.0869C277.003 41.0656 266.482 51.5869 253.503 51.5869C240.524 51.5869 230.003 41.0656 230.003 28.0869C230.003 15.1082 240.524 4.58691 253.503 4.58691C266.482 4.58691 277.003 15.1082 277.003 28.0869ZM246.95 28.0869C246.95 31.706 249.884 34.6399 253.503 34.6399C257.122 34.6399 260.056 31.706 260.056 28.0869C260.056 24.4678 257.122 21.5339 253.503 21.5339C249.884 21.5339 246.95 24.4678 246.95 28.0869Z" fill="#FFC700" />
          <path d="M403.003 26.0869H593.006" stroke="#9BBBF5" stroke-width="2" />
        </svg>
      </div>

      <p className="container-benefit-partners-title">
        <TitleUnderline underline="Beneficios" />    de nuestro programa
      </p>
      <section>
        <section className="container-benefit-partners-cards">
          {data.map((elemento, i) => {
            return (
              <div className="container-benefit-partners-cards-card" key={i}>
                <p className="container-benefit-partners-cards-card-title">
                  {elemento.title}
                  <span style={{ color: elemento.color }}>*</span>
                </p>
                <p className="container-benefit-partners-cards-card-text">
                  {elemento.text}
                </p>
              </div>
            )
          })}
        </section>
      </section>
    </div>
  )
}
export default Benefit
