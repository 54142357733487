import React, { useState } from "react"
import TitleUnderline from "./titleUnderline"


const Customer = ({ data }) => {
  //estado para el texto de la caja en desktop
  const [text, settext] = useState(
    "Ofrece a tus clientes una solución omnicanal que amplíe el contacto masivo con su público objetivo y mejore la experiencia de comunicación."
  )
  const [comilla, setComilla] = useState("rojo")
  const support = (event, elemento) => {
    event.preventDefault()
    settext(elemento.text)
    setComilla(elemento.color)
  }

  const comillas = () => {
    if (comilla === "rojo") {
      return "container-whocan-partner-comillas-rojo"
    }
    if (comilla === "celeste") {
      return "container-whocan-partner-comillas-celeste"
    }
    if (comilla === "naranja") {
      return "container-whocan-partner-comillas-naranja"
    }
    if (comilla === "verde") {
      return "container-whocan-partner-comillas-verde"
    }
    if (comilla === "azul") {
      return "container-whocan-partner-comillas-azul"
    }
  }

  const [active, setActive] = useState(true)

  return (
    <section className="container-whocan-partner whocan-desktop">
      <p className="container-whocan-partner-title">
      ¿Quiénes pueden <TitleUnderline underline="participar?" />    
        </p>
      <section className="container-whocan-partner-cards">
        <div style={{display:"flex", justifyContent: "center"}}>
          {data.map(elemento => {
            return (
              <div
                class={`carta-box ${
                  active && elemento.id === 0 ? "card-partners-active" : ""
                } `}
                onMouseOver={event => {
                  support(event, elemento)
                  setActive(false)
                }}
                key={elemento.id}
              >
                <div class={`cara ${Detail(elemento)}`}>
                  <img src={elemento.img} className="card-icon" alt="..." />
                  <p className="container-whocan-partner-card-title">
                    {elemento.title}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
        <div className="container-whocan-partner-card-text-description container-whocan-partner-card-text-description-desktop">
          <p className="container-whocan-partner-card-text">
            <span className={comillas()}>“</span> {text}{" "}
            <span className={comillas()}>”</span>{" "}
          </p>
        </div>
      </section>
    </section>
  )
}
export default Customer

const Detail = elemento => {
  if (elemento.color === "rojo") {
    return "container-whocan-partner-card-border-rojo"
  }
  if (elemento.color === "celeste") {
    return "container-whocan-partner-card-border-celeste"
  }
  if (elemento.color === "naranja") {
    return "container-whocan-partner-card-border-naranja"
  }
  if (elemento.color === "verde") {
    return "container-whocan-partner-card-border-verde"
  }
  if (elemento.color === "azul") {
    return "container-whocan-partner-card-border-azul"
  }
}
