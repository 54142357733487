/* eslint-disable no-undef */
import React from "react"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"


import data from "@components/pagePartners/data"
import Start from "@components/pagePartners/components/start/start"
import WhoCanResponsive from "@components/pagePartners/components/whoCan/whoCanResponsive"
import WhoCan from "@components/pagePartners/components/whoCan/whoCan"
import Benefit from "@components/pagePartners/components/benefit/benefit"
import Levels from "@components/pagePartners/components/levels/levels"
import Product from "@components/pagePartners/components/product/product"
import Questions from "@components/pagePartners/components/questions/questions"

const TemplatePagePartners = ({ location }) => {
  const windowsWidth = true

  return (
    <div className="fnd">
      {/* Etiquetas H1 y H2 del Slider */}
      <div style={{ display: "none" }}>
        <h1>Sé un partner de Beex</h1>
      </div>
      <section>
        <BannerCookies />
        <section>
          <div>
            <Header
              path="/programa-partners-beex/"
              location={location}
              bannerCTA={false}
              windowsWidth={windowsWidth}
            />
          </div>

          <div className="container">
            <Start data={data.start} logo={data.logo} location={location} />
            <WhoCan data={data.whoCan} />
            <WhoCanResponsive data={data.whoCan} />
            <Benefit data={data.benefit} />
            <Levels data={data.levels} location={location} />
            <Product data={data.product} />
            <Questions data={data.questions} />
            <BannerContact data={data.contacts} location={location} />
            <Footer data={data.footer} location={location} />
            <FooterMobile data={data.footer} location={location} />
          </div>
        </section>
      </section>
    </div>
  )
}

export default TemplatePagePartners
