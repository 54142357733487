import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg style={{bottom: "0px"}} width="187" height="9" viewBox="0 0 187 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.7662 9C30.9001 8.16452 2.59126 9.09727 0.533606 8.84548C-1.14628 8.50786 1.53832 2.5622 3.09764 1.68666C3.6442 1.37192 4.27918 1.32616 5.73401 1.46922C8.04083 1.73818 37.8688 1.20598 37.8688 1.20598C38.2626 1.38909 39.4442 0.77107 39.6612 0.994246C40.3444 1.51499 88.6912 0.805402 92.8146 0.77679C95.3143 0.759622 93.337 1.11441 96.2788 0.971352C102.548 0.685228 116.212 0.959909 120.963 0.633728C124.764 0.364771 125.584 0.353335 129.233 0.570789C132.963 0.765353 152.792 0.484949 155.059 0.0672084C155.613 -0.0472411 156.039 -0.00718033 156.039 0.124437C156.039 0.153049 170.017 0.250328 170.081 0.353332C170.186 0.484949 170.612 0.547892 171.005 0.456332C171.737 0.318993 186.615 -0.178864 186.848 0.204542C187.579 1.30326 185.53 5.98424 183.801 7.12301C182.033 8.3419 172.324 6.97424 164.519 7.70672L128.181 7.78111L120.392 8.2389C119.5 7.84405 116.068 8.83976 113.994 8.13017C113.118 7.84977 106.929 7.80971 105.562 8.10728C104.373 8.33618 72.7123 7.75821 69.465 8.55363C63.6779 7.88982 40.2077 8.83977 34.7662 9Z" fill="#FFA700" />
    </svg>
  </span>
)

export default titleUnderline
