import React from "react"
import logo from "@components/pagePartners/images/logo.png"

// iconos para card quienes pueden participar
import megafono from "@components/pagePartners/images/megafono.svg"
import bombilla from "@components/pagePartners/images/bombilla.svg"
import computadora from "@components/pagePartners/images/computadora.svg"
import nube from "@components/pagePartners/images/nube.svg"
import auricular from "@components/pagePartners/images/auricular.svg"

//imagenes cards productos
import ticker from "@components/pagePartners/images/ticker.webp"
import score from "@components/pagePartners/images/score.webp"


const data = {
  start: {
    logo: logo,
    textbody:
      "Conviértete en un partner nuestro y empieza a generar ingresos extra vendiendo o refiriendo a otros negocios nuestros productos tecnológicos para Contact Center y soluciones omnicanales para negocios en general. ",
  },
  whoCan: [
    {
      title: "Agencias de marketing",
      img: megafono,
      text:
        "Ofrece a tus clientes una solución omnicanal que amplíe el contacto masivo con su público objetivo y mejore la experiencia de comunicación.",
      color: "rojo",
      id: 0
    },
    {
      title: "Proveedores de soluciones",
      img: bombilla,
      text:
        "Perfecciona la suite de tus servicios añadiendo soluciones relacionadas a operaciones de Contact Center omnicanales en la nube.",
      color: "celeste",
      id: 1
    },
    {
      title: "Revendedores de software ",
      img: computadora,
      text:
        "Amplía tu cartera de productos con software en la nube para operaciones de Contact Center, bajo diferentes modalidades de suscripciones.",
      color: "naranja",
      id: 2
    },
    {
      title: "Empresas de consultoría",
      img: nube,
      text:
        "Haz que tus clientes mejoren sus procesos de atención al cliente, marketing, ventas y cobranzas con nuestras plataformas omnicanales en la nube.",
      color: "verde",
      id: 3
    },
    {
      title: "Tercerizadores & BPO",
      img: auricular,
      text:
        "Recomienda o terceriza software de Contact Center en la nube para atención al cliente, telemarketing, televentas y cobranzas.",
      color: "azul",
      id: 4
    },
  ],
  benefit: [
    {
      title: "Precios por volúmenes agresivos",
      text: "Obtén los mejores precios por licencias y aumenta tus ganancias.",
      color: "transparent"
    },
    {
      title: "Bonos y estrategias de marketing",
      text: "Accede a eventos y acciones conjuntas de publicidad.",
      color: "transparent"
    },
    {
      title: "Capacitaciones constantes",
      text: "Conviértete en un experto en nuestras soluciones omnicanales.",
      color: "transparent"
    },
    {
      title: "Rentabiliza tus servicios profesionales",
      text: "Genera ingresos por consultorías profesionales que vayan 100% para ti.",
      color: "transparent"
    },
    {
      title: "Acceso a Pre-lanzamientos",
      text: "Prueba nuestras nuevas funcionalidades antes de que salgan al mercado.",
      color: "transparent"
    },
    {
      title: "Calendario de updates de productos",
      text: "Accede a las actualizaciones de nuestras soluciones, antes que nadie.",
      color: "transparent"
    },
    {
      title: "Acceso a contenido de marketing y ventas",
      text: "Obtén recursos digitales de la marca para mejorar tu conversión digital.",
      color: "transparent"
    },
    {
      title: "Acceso a Account Manager",
      text: "Cuenta con ayuda de un profesional para mejorar tu proceso de venta.",
      color: "transparent"
    },
  ],

  product: [
    {
      title: "Beex Conversations, atención al cliente omnicanal",
      img: ticker,
      link: "/conversations/",
      text:
        "Puedes conseguir una gestión multiagente, chatbots, la API de WhatsApp Business, unificación de canales de atención y mucho más.",
    },
    {
      title: "Beex Contact Center en la nube",
      img: score,
      link: "/contact-center/",
      text:
        "Nuestra plataforma CCaaS ofrece marcadores inteligentes, módulos de supervisión en tiempo real, además de reportes y dashboards de gestión.",
    },
  ],

  contacts: {
    title: "¿Aún te quedan dudas?",
    textbody:
      "Estamos para ayudarte. Por eso, si aun tienes dudas de nuestro programa o quieres más información de uno de nuestros niveles, puedes ponerte en contacto con uno de nuestros especialistas.",
    btn: "Quiero saber más",
    href: "/programa-partners-beex/formulario/",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
}

export default data
