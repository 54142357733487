import React from "react"
import { Link } from "gatsby"
import TitleUnderline from "./titleUnderline"


const Customer = ({ data }) => {
  return (
    <section>
      <div className="container-benefit-logo-banner">
        <svg width="593" height="52" viewBox="0 0 593 52" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 26.0869H190.003" stroke="#9BBBF5" stroke-width="2" />
          <circle cx="318.503" cy="12.0869" r="11.5" fill="#F2ACA9" />
          <circle cx="357.003" cy="38.5869" r="6" fill="#66E28E" />
          <circle cx="300.003" cy="37.5869" r="7" fill="#88AEF3" />
          <path d="M277.003 28.0869C277.003 41.0656 266.482 51.5869 253.503 51.5869C240.524 51.5869 230.003 41.0656 230.003 28.0869C230.003 15.1082 240.524 4.58691 253.503 4.58691C266.482 4.58691 277.003 15.1082 277.003 28.0869ZM246.95 28.0869C246.95 31.706 249.884 34.6399 253.503 34.6399C257.122 34.6399 260.056 31.706 260.056 28.0869C260.056 24.4678 257.122 21.5339 253.503 21.5339C249.884 21.5339 246.95 24.4678 246.95 28.0869Z" fill="#FFC700" />
          <path d="M403.003 26.0869H593.006" stroke="#9BBBF5" stroke-width="2" />
        </svg>
      </div>

      <p className="container-questions-partners-title">
        <TitleUnderline underline="¡Conoce" /> nuestros productos
      </p>
      <section className="container-product-partners">
        {data.map((elemento, i) => {
          return (
            <div className="container-product-partners-card" key={i}>
              <img src={elemento.img} alt="" />
              <p className="container-product-partners-card-title">
                {elemento.title}
              </p>
              <p className="container-product-partners-card-text">
                {elemento.text}
              </p>
              <Link to={elemento.link} className="container-product-partners-button">
                Conocer más
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.27567 18.0005C1.17418 18.001 1.07356 17.9821 0.979602 17.9448C0.885641 17.9075 0.80018 17.8525 0.728119 17.783C0.655836 17.7133 0.598463 17.6304 0.559311 17.539C0.520158 17.4476 0.5 17.3496 0.5 17.2506C0.5 17.1516 0.520158 17.0536 0.559311 16.9622C0.598463 16.8708 0.655836 16.7879 0.728119 16.7182L7.02881 10.5917C7.46207 10.1699 7.70543 9.59817 7.70543 9.00202C7.70543 8.40587 7.46207 7.83409 7.02881 7.41229L0.728119 1.28584C0.5829 1.14463 0.501316 0.95312 0.501316 0.753428C0.501316 0.553736 0.5829 0.362223 0.728119 0.221019C0.873339 0.0798156 1.0703 0.000488281 1.27567 0.000488281C1.48104 0.000488281 1.678 0.0798156 1.82322 0.221019L8.12391 6.34747C8.48301 6.69575 8.76791 7.1095 8.9623 7.56501C9.1567 8.02052 9.25676 8.50885 9.25676 9.00202C9.25676 9.49519 9.1567 9.98351 8.9623 10.439C8.76791 10.8945 8.48301 11.3083 8.12391 11.6566L1.82322 17.783C1.75116 17.8525 1.6657 17.9075 1.57174 17.9448C1.47778 17.9821 1.37717 18.001 1.27567 18.0005Z"
                    fill="#5831D8"
                  />
                </svg>
              </Link>
            </div>
          )
        })}
      </section>
    </section>
  )
}
export default Customer
